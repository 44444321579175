/* eslint-disable max-lines-per-function */
import { Box, Flex } from '@chakra-ui/react';
import { DateFormats, append, dayjs, now } from '@ours/utils';
import { FullCenteredSquiggleLoader, useDayjs, useToArray } from '@ours/web-utils';
import { gql } from 'graphql-tag';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import type { GuideDashboardFragment } from '../../../generated/custom-hooks';
import { useGuideDashboardQuery } from '../../../generated/custom-hooks';

import { DisplayCouplesInStatus } from './DisplayCouplesInStatus';
import type { DashboardProps } from './types';

gql`
  fragment GuideDashboard on Membership {
    id
    guideEndsAt
    welcomeSessionDate
    sessionStatus
    combinedStudentsName
    sessionIds
    completedContentSessionIds
    completedSessionIds
    membershipState
    createdAt
  }

  query guideDashboard($fourMonthsAgo: String!, $guideId: String) {
    missingEndingDate: queryMemberships(
      query: { take: 5000 }
      filter: { guideId: { eq: $guideId }, guideEndsAt: [{ eq: null }, { exists: false }] }
    ) {
      entities {
        ...GuideDashboard
      }
    }

    activeCouples: queryMemberships(
      query: { take: 5000 }
      filter: { guideId: { eq: $guideId }, guideEndsAt: [{ gte: $fourMonthsAgo }] }
    ) {
      entities {
        ...GuideDashboard
      }
    }
  }
`;

export const GuideDashboard: FC<DashboardProps> = memo(
  ({ guideId, loading: outerLoading, skip }) => {
    const nowMemo = useMemo(() => now(), []);
    const fourMonthsAgo = useMemo(
      () => dayjs().subtract(4, 'months').format(DateFormats.DatabaseDate),
      []
    );

    const today = useDayjs(nowMemo, { format: 'DatabaseDate' });
    const { data, loading } = useGuideDashboardQuery({
      skip: !today || skip,
      variables: { fourMonthsAgo, guideId },
    });
    const missingDate = useToArray(data?.missingEndingDate?.entities);
    const activeCouples = useToArray(data?.activeCouples?.entities);

    const partitionedCouples = useMemo(() => {
      const nowDJ = dayjs();
      let upcomingWelcomeSessions: GuideDashboardFragment[] = [];
      let roadmapReady: GuideDashboardFragment[] = [];
      let completed: GuideDashboardFragment[] = [];
      let roadmapBeingBuilt: GuideDashboardFragment[] = [];
      let incompletePastEndsAt: GuideDashboardFragment[] = [];

      activeCouples.forEach((couple) => {
        if (couple.membershipState === 'Complete') {
          completed = append(couple, completed);
        } else if (dayjs(couple.guideEndsAt).isBefore(nowDJ)) {
          incompletePastEndsAt = append(couple, incompletePastEndsAt);
        } else if (
          couple.sessionStatus !== 'SessionsReady' &&
          dayjs(couple.welcomeSessionDate).isAfter(nowDJ)
        ) {
          upcomingWelcomeSessions = append(couple, upcomingWelcomeSessions);
        } else if (
          couple.sessionStatus === 'SessionsReady' &&
          dayjs(couple.guideEndsAt).isAfter(nowDJ)
        ) {
          roadmapReady = append(couple, roadmapReady);
        } else if (couple.sessionStatus !== 'SessionsReady') {
          roadmapBeingBuilt = append(couple, roadmapBeingBuilt);
        }
      });

      return {
        completed,
        incompletePastEndsAt,
        roadmapBeingBuilt,
        roadmapReady,
        upcomingWelcomeSessions,
      };
    }, [activeCouples]);

    if (outerLoading || loading) {
      return <FullCenteredSquiggleLoader />;
    }

    return (
      <Box pb="240" pt="240" px="120">
        <Flex gap="100" py="200" w="full">
          <DisplayCouplesInStatus
            couples={partitionedCouples.upcomingWelcomeSessions}
            sortBy="welcomeSessionDate"
            subtitle="Upcoming welcome session & sessions not ready"
            title="Upcoming Welcome Sessions"
          />
          <DisplayCouplesInStatus
            couples={partitionedCouples.roadmapBeingBuilt}
            sortBy="welcomeSessionDate"
            subtitle="Welcome session in the past & roadmap is not ready"
            title="Roadmap In Progress"
          />
        </Flex>
        <Flex gap="100" py="200" w="full">
          <DisplayCouplesInStatus
            couples={partitionedCouples.roadmapReady}
            displayCompletedContentSessions
            sortBy="welcomeSessionDate"
            subtitle="Sessions ready & with guide access"
            title="Currently in Roadmap"
          />
          <DisplayCouplesInStatus
            couples={partitionedCouples.incompletePastEndsAt}
            sortBy="welcomeSessionDate"
            subtitle="Incomplete & after guide ends at"
            title="Incomplete"
          />
          <DisplayCouplesInStatus
            couples={partitionedCouples.completed}
            sortBy="welcomeSessionDate"
            subtitle="Completed everything"
            title="Completed"
          />
        </Flex>
        <Flex>
          <DisplayCouplesInStatus
            couples={missingDate}
            sortBy="welcomeSessionDate"
            subtitle="Missing end date"
            title="End date missing"
          />
        </Flex>
      </Box>
    );
  }
);
