import { Box } from '@chakra-ui/react';
import { Border, RoundedCorners, cloneDeep, dayjs } from '@ours/utils';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import type { GuideDashboardFragment } from '../../../generated/custom-hooks';

import { DisplayCouple } from './DisplayCouple';

export interface Props {
  couples: readonly GuideDashboardFragment[];
  displayCompletedContentSessions?: boolean;
  sortBy?: 'guideEndsAt' | 'welcomeSessionDate' | 'createdAt';
  subtitle: string;
  title: string;
}

export const DisplayCouplesInStatus: FC<Props> = memo(
  ({ couples, displayCompletedContentSessions, sortBy, subtitle, title }) => {
    const sortedCouples = useMemo(() => {
      if (!sortBy) {
        return couples;
      }

      return cloneDeep([...couples]).sort((a, b) => {
        return dayjs(a[sortBy]).isAfter(dayjs(b[sortBy])) ? 1 : -1;
      });
    }, [couples, sortBy]);

    return (
      <Box
        border={Border}
        flexGrow={1}
        maxH="450px"
        minW="30%"
        overflowY="scroll"
        p="150"
        {...RoundedCorners.Rotating}
      >
        <Box fontWeight="600" textStyle="lg">
          {title}
        </Box>
        <Box textStyle="xs">{subtitle}</Box>
        {sortedCouples.length !== 0 ? (
          <Box textStyle="2xs">{sortedCouples.length} couples</Box>
        ) : null}

        <Box py="200">
          {sortedCouples.map((couple) => (
            <DisplayCouple
              couple={couple}
              displayCompletedContentSessions={displayCompletedContentSessions}
              key={couple.id}
            />
          ))}
          {sortedCouples.length === 0 ? <Box textStyle="sm">No Couples</Box> : null}
        </Box>
      </Box>
    );
  }
);
