import { Box } from '@chakra-ui/react';
import { toCouple } from '@ours/utils';
import { BlueLink, Pii, useDayjs } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

import type { GuideDashboardFragment } from '../../../generated/custom-hooks';

export interface Props {
  couple: GuideDashboardFragment;
  displayCompletedContentSessions?: boolean;
}

export const DisplayCouple: FC<Props> = memo(({ couple, displayCompletedContentSessions }) => {
  const date = useDayjs(couple.welcomeSessionDate, {
    fallbackText: '(Missing date)',
    format: 'DateTimeZone',
  });

  return (
    <Box key={couple.id} py="50">
      <Box>
        <BlueLink as="a" href={toCouple(couple.id)}>
          <Pii>{couple.combinedStudentsName || ''}</Pii>
        </BlueLink>
      </Box>
      <Box textStyle="xs">Welcome Session on {date}</Box>
      {displayCompletedContentSessions ? (
        <Box textStyle="xs">
          {couple.completedContentSessionIds?.length || 0} / {couple.sessionIds?.length || 0}
        </Box>
      ) : null}
    </Box>
  );
});
