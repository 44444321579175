import { mask } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useIsShowingPII } from '../../state/adminMachine/selectors/useIsShowingPII';

export interface PiiProps {
  children: string;
}

export const Pii: FC<PiiProps> = memo(({ children }) => {
  const isMachineShowingPII = useIsShowingPII();

  if (isMachineShowingPII) {
    return <>{children}</>;
  }

  return <>{mask(children)}</>;
});
