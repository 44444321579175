import { Box } from '@chakra-ui/react';
import type { Role } from '@ours/utils';
import { useUserRole } from '@ours/web-utils';
import type { FC } from 'react';
import { memo } from 'react';

import { AdminDashboard } from './lib/AdminDashboard';
import { GuideDashboard } from './lib/GuideDashboard';
import type { DashboardProps } from './lib/types';

const dashboardLookupByRole: Record<Role, FC<DashboardProps>> = {
  ADMIN: AdminDashboard,
  CREATOR: () => <Box>none</Box>,
  CUSTOMER: () => <Box>none</Box>,
  ClinicalAdmin: AdminDashboard,
  GUIDE: GuideDashboard,
  SUPERVISOR: AdminDashboard,
};

export const AdminDashboardContainer: FC = memo(() => {
  const role = useUserRole();
  if (!role) {
    return null;
  }
  const Component = dashboardLookupByRole[role];

  return <Component role={role || 'CUSTOMER'} />;
});
