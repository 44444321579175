import type { NextPageWithLayout } from '@ours/types';
import { useAppActions, useAppStateValue } from '@ours/web-utils';
import { memo, useEffect } from 'react';

import { SidebarLayout } from '../components/SidebarLayout/SidebarLayout';
import { AdminDashboardContainer } from '../features/AdminDashboard/AdminDashboardContainer';

const Home: NextPageWithLayout = memo(() => {
  const auth = useAppStateValue();

  const title = auth === 'unauthenticated' ? 'Login' : 'Dashboard';
  const { onSetTitle } = useAppActions();
  useEffect(() => {
    onSetTitle(title);
  }, [onSetTitle, title]);

  return <AdminDashboardContainer />;
});

Home.Layout = SidebarLayout;

export default Home;
