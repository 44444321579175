import { Box, Flex } from '@chakra-ui/react';
import { first } from '@ours/utils';
import { Select, useEvent, useUserId } from '@ours/web-utils';
import { gql } from 'graphql-tag';
import { useRouter } from 'next/router';
import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useMemo } from 'react';

import { useGetGuideUsersQuery } from '../../../generated/custom-hooks';

import { GuideDashboard } from './GuideDashboard';
import type { DashboardProps } from './types';

gql`
  query getGuideUsers {
    queryUsers(
      sort: [{ field: fullName, order: Asc }]
      query: { take: 300 }
      filter: { role: { in: [ClinicalAdmin, ADMIN, GUIDE, SUPERVISOR] } }
    ) {
      entities {
        id
        fullName
        email
      }
    }
  }
`;
export const AdminDashboard: FC<DashboardProps> = memo((props) => {
  const userId = useUserId();
  const router = useRouter();
  const guideId = useMemo(() => router.query.guideId as string, [router.query.guideId]);
  const setGuideId = useEvent((guideId: string) => {
    router.push({ query: { guideId } });
  });
  const { data, loading } = useGetGuideUsersQuery({
    onCompleted: (d) => {
      if (!guideId) {
        const users = d.queryUsers?.entities || [];
        const preferred = users.find(({ id }) => id === userId) || first(users);
        setGuideId(preferred?.id || '');
      }
    },
  });

  const users = useMemo(() => {
    return (data?.queryUsers?.entities || []).map(({ email, fullName, id }) => ({
      label: fullName || email || '',
      value: id,
    }));
  }, [data?.queryUsers?.entities]);

  const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      setGuideId(event.currentTarget.value);
    },
    [setGuideId]
  );

  return (
    <Box pt="120" px="120">
      <Flex justifyContent="flex-end">
        <Box w="300px">
          <Select loading={loading} onChange={onChange} options={users} size="sm" value={guideId} />
        </Box>
      </Flex>
      <GuideDashboard {...props} guideId={guideId} loading={loading} skip={!guideId} />
    </Box>
  );
});
