/**
 * Takes text (usually an email or name) and masks it.
 *
 * I (TZ) couldn't really find a compelling package to do this. Open to using one if we find a good one.
 */
export const mask = (text: string, isShowingPII?: boolean): string => {
  if (isShowingPII) {
    return text;
  }
  if (!text) {
    return '';
  }
  const isMultiWord = text.split(' ').length > 1;

  if (isMultiWord) {
    return text
      .split(' ')
      .map((v) => mask(v))
      .join(' ');
  }

  if (text.toLowerCase() === 'and') {
    return text;
  }
  const maskSymbol = '*';

  const isEmail = text.includes('@');

  if (isEmail) {
    const x = text.lastIndexOf('.');
    const shownChar = 2;
    return [text.slice(0, shownChar), maskSymbol.repeat(x - shownChar), text.slice(x)].join('');
  }

  return [text.slice(0, 1), maskSymbol.repeat(text.length - 1)].join('');
};
